import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useGetRolesQuery } from "../../hooks/Auth/useGetRolesQuery";

function useAuth() {
  const googleProfile = localStorage.getItem("google_credential");
  const googleProfileJson = googleProfile ? JSON.parse(googleProfile) : null;
  const isAuthenticated = googleProfileJson?.token ? true : false;
  return { isAuthenticated, profile: googleProfileJson };
}

function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element | null {
  let navigate = useNavigate();
  const { isAuthenticated, profile } = useAuth();
  const { isLoading: rolesLoading, data: roles } = useGetRolesQuery();

  const isAuthorized = useCallback(
    (profile: any, role: string) => {
      const authRole = roles?.find((r: any) => r.role === role);
      return profile?.role === authRole?.id;
    },
    [roles]
  );

  useEffect(() => {
    if (!rolesLoading) {
      if (!isAuthenticated) {
        navigate("/auth/login");
      }

      if (!rolesLoading && isAuthenticated && !isAuthorized(profile, "ADMIN")) {
        navigate("/unauthorized");
      }
    }
  }, [isAuthenticated, isAuthorized, profile, navigate, roles, rolesLoading]);

  if (!rolesLoading && isAuthenticated && profile) {
    return children;
  } else {
    return null;
  }
}

function AuthenticatedRedirect({ children }: { children: JSX.Element }): JSX.Element | null {
  let navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/applications");
    }
  }, [isAuthenticated, navigate]);

  return children;
}

export { AuthenticatedRedirect, useAuth, PrivateRoute };
