import { Box } from "@mui/joy";

const StaticLayout = ({ children, ...props }: { children: React.ReactNode }): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      {...props}
    >
      {children}
    </Box>
  );
};

export default StaticLayout;
