import axios from "axios";

export const fetchGoogleAuth = async (token: string) => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/google-auth`, { token });
  return res.data;
};

export const fetchFromApi = async (url: string, method: string, data?: any) => {
  const res = await axios({
    method,
    url: `${process.env.REACT_APP_API_URL}${url}`,
    data,
  });
  return res.data;
};
