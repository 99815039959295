import React from "react";
import { Input, Select, Option, Checkbox, FormControl, FormLabel } from "@mui/joy";

interface InputProps {
  type: "text" | "date" | "checkbox" | "select";
  name: string;
  value?: string | boolean;
  placeholder?: string;
  options?: string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onSelectChange?: (name: string, value: string | {}) => void;
}

const InputField: React.FC<InputProps> = ({
  type,
  name,
  value,
  placeholder,
  options,
  onChange,
  onSelectChange,
}) => {
  switch (type) {
    case "text":
    case "date":
      return (
        <FormControl>
          {placeholder && value ? <FormLabel>{placeholder}</FormLabel> : null}
          <Input
            type={type}
            name={name}
            defaultValue={
              type === "date"
                ? new Date(value as string).toISOString().split("T")[0]
                : value?.toString()
            }
            placeholder={placeholder}
            onChange={onChange}
          />
        </FormControl>
      );
    case "checkbox":
      return <Checkbox name={name} label={placeholder} onChange={onChange} />;
    case "select":
      if (!onSelectChange) return null;
      return (
        <FormControl>
          {placeholder && value ? <FormLabel>{placeholder}</FormLabel> : null}
          <Select
            name={name}
            placeholder={placeholder}
            defaultValue={value as string}
            onChange={(e, newValue) => (newValue ? onSelectChange(name, newValue) : null)}
          >
            {options?.map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </FormControl>
      );
    default:
      return null;
  }
};

export default InputField;
