import { useQuery } from "@tanstack/react-query";
import { fetchFromApi } from "../../api/base";

async function getUserRequest(userId: string) {
  const response = await fetchFromApi(`/users/request-access/${userId}`, "GET");
  if (!response) {
    throw new Error("There was an error getting the roles");
  }
  return response;
}

export function useGetUserRequestQuery(userId: string) {
  return useQuery({
    queryKey: ["request"],
    queryFn: () => getUserRequest(userId),
  });
}
