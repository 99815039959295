import { useRoutes } from "react-router-dom";
import ApplicationList from "./List";
import ApplicationProfile from "./Profile";

function ApplicationsRouter() {
  let routes = useRoutes([
    {
      path: "/",
      element: <ApplicationList />,
    },
    {
      path: "/:applicationId",
      element: <ApplicationProfile />,
    },
    {
      path: "/status/:status",
      element: <ApplicationList />,
    },
  ]);

  return routes;
}

export default ApplicationsRouter;
