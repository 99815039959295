/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Table from "@mui/joy/Table";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Button, Sheet } from "@mui/joy";
import { PersonOutlined } from "@mui/icons-material";

import { application_status_map, getStatusIcon } from "./Detail";

// function RowMenu() {
//   return (
//     <Dropdown>
//       <MenuButton
//         slots={{ root: IconButton }}
//         slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
//       >
//         <MoreVertOutlined fontSize="medium" />
//       </MenuButton>
//       <Menu size="sm" sx={{ minWidth: 140 }}>
//         <MenuItem>Edit</MenuItem>
//         <MenuItem>Rename</MenuItem>
//         <MenuItem>Move</MenuItem>
//         <Divider />
//         <MenuItem color="danger">Delete</MenuItem>
//       </Menu>
//     </Dropdown>
//   );
// }

const fetchApplications = async (status: string | undefined) => {
  let reqUrl = process.env.REACT_APP_API_URL + "/application" || "";
  reqUrl += status ? `/status/${status}` : "";
  const res = await axios.get(reqUrl);
  return res.data;
};

export const fetchApplication = async (id: string) => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/application/${id}`);
  return res.data;
};

export const useApplications = (
  status: string | undefined
): {
  data: Application[];
  isLoading: boolean;
  isError: boolean;
  error: unknown;
} => {
  return useQuery({
    queryKey: ["applications", status],
    queryFn: () => fetchApplications(status),
    initialData: [],
  });
};

export type Application = {
  address1?: string;
  annualSalary?: string;
  city?: string;
  convictionDetail?: string;
  date?: string;
  dob?: string;
  email?: string;
  employmentStatus?: number;
  firstName?: string;
  hasConvictions?: boolean;
  hasLegalAction?: boolean;
  haveBc?: boolean;
  haveDl?: boolean;
  haveSs?: boolean;
  highestEducation?: string;
  howSoon?: number;
  id?: string;
  isDrugFree?: boolean;
  lastName?: string;
  legalDetail?: string;
  onParole?: boolean;
  onProbation?: boolean;
  personalContactName?: string;
  personalContactPhone?: string;
  phone?: string;
  preferredContact?: string;
  programId?: string;
  state?: string;
  status?: number;
  userId?: string;
  weeklyHours?: number;
  whyInterested?: string;
  zip?: string;
};
const ApplicationList = () => {
  const { status } = useParams();
  const { data, isLoading, isError, error } = useApplications(status);
  const formattedDate = (date: string) => new Date(date).toLocaleDateString();

  if (isError) {
    return <div>Error: {JSON.stringify(error)}</div>;
  }

  return (
    <Sheet sx={{ minHeight: "100vh", p: 4 }}>
      <Box mb={3} display={"flex"} justifyContent={"space-between"}>
        <Typography level="h2">Applications</Typography>
        <RouterLink to="/apply">
          <Button variant="soft" color="primary">
            Create New Application
          </Button>
        </RouterLink>
      </Box>
      <Table size="lg" aria-label="Application List">
        <thead>
          <tr>
            <td style={{ width: 75, textAlign: "center", verticalAlign: "middle" }}>Status</td>
            <td style={{ width: 150, verticalAlign: "middle" }}>Name</td>
            <td style={{ width: 160, verticalAlign: "middle" }}>Phone</td>
            <td style={{ width: 350, verticalAlign: "middle" }}>Email</td>
            <td style={{ width: 125, verticalAlign: "middle" }}>Date</td>
            <td style={{ width: 65, verticalAlign: "middle" }}>Actions</td>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <></>
          ) : (
            data.map((applicant: Application) => (
              <tr key={applicant.id} style={{ borderBottom: "1px solid #fff" }}>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {getStatusIcon(applicant.status!)}
                  <Typography level="body-sm" sx={{ mt: 1 }}>
                    {application_status_map[applicant.status!]}
                  </Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography level="body-md">
                    {applicant.firstName} {applicant.lastName}
                  </Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography level="body-md">{applicant.phone}</Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography level="body-md">{applicant.email}</Typography>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Typography level="body-md">{formattedDate(applicant.date!)}</Typography>
                </td>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                  <Link component={RouterLink} to={`/applications/${applicant.id}`}>
                    <IconButton
                      aria-label="view application"
                      variant="outlined"
                      color="neutral"
                      size="sm"
                      sx={{ mr: 1 }}
                    >
                      <PersonOutlined />
                    </IconButton>
                  </Link>
                  {/* <RowMenu /> */}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Box
        className="Pagination-mobile"
        sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", py: 2 }}
      >
        <IconButton aria-label="previous page" variant="outlined" color="neutral" size="sm">
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography level="body-sm" mx="auto">
          Page 1 of 10
        </Typography>
        <IconButton aria-label="next page" variant="outlined" color="neutral" size="sm">
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
};

export default ApplicationList;
