import React, { useEffect, useState } from "react";
import { Typography, Button, Card, CardContent, Box, Stack } from "@mui/joy";
import StaticLayout from "./StaticLayout";
import { useAuth } from "../Routes/PrivateRoute";
import { fetchFromApi } from "../../api/base";
import { ReactComponent as Logo } from "../../assets/images/brand/tap-icon-logo.svg";
import { useNavigate, useParams } from "react-router";
import { useGetUserRequestQuery } from "../../hooks/User/useGetUserRequestQuery";

const ConfirmUser = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const { isAuthenticated, profile } = useAuth();
  const { id: userId } = useParams();
  const navigate = useNavigate();

  const { isLoading: requestLoading, data: requestData } = useGetUserRequestQuery(userId || "");
  const loading = requestLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/confirm-user/${userId}/` } });
    }
  }, [isAuthenticated, profile, navigate, userId]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const res = await fetchFromApi(`/users/request-access/${userId}/confirm`, "PUT");
    setSuccessMessage(res);
  };

  return loading ? (
    <div>Loading...</div>
  ) : (
    <StaticLayout>
      <Card sx={{ mb: 2, width: 450 }} size="md">
        <CardContent sx={{ alignItems: "center", display: "flex", p: 2 }}>
          <Box sx={{ mb: 1 }}>
            <Logo width={50} />
          </Box>
          <Typography level="h3" component="h1" mb={2} gutterBottom>
            Community Resources
          </Typography>
          {successMessage ? (
            <Box mb={2}>
              <Typography level="body-sm" variant="outlined" color="success" p={2}>
                {successMessage}
              </Typography>
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Typography level="body-sm">
                You are about to confirm the selected user for the specified role type. Please
                review the information below and click the "Confirm" button to proceed.
              </Typography>
              <br />
              <Stack width={350} spacing={2} sx={{ mt: 2, mb: 6 }}>
                <Typography level="body-sm">{/* <b>Email:</b> {userData.email} */}</Typography>
                <Typography level="body-sm">
                  <b>Type:</b> {requestData.type}
                </Typography>
                <Typography level="body-sm">
                  <b>Message: </b>
                  {requestData?.message}
                </Typography>
              </Stack>
              <Button type="submit" color="primary">
                Confirm
              </Button>
            </form>
          )}
        </CardContent>
      </Card>
    </StaticLayout>
  );
};

export default ConfirmUser;
