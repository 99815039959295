import { useMutation } from "@tanstack/react-query";
import { fetchFromApi } from "../../api/base";
import { Application } from "../../types/Application";

export interface CreateApplicationData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dob: Date;
  address1: string;
  city: string;
  state: string;
  zip: string;
  preferredContact: string;
  haveBc: boolean;
  haveDl: boolean;
  haveSs: boolean;
  whyInterested: string;
  howSoon: number;
  highestEducation: string;
  employmentStatus: number;
  annualSalary: string;
  weeklyHours: number;
  isDrugFree: boolean;
  hasLegalAction: boolean;
  legalDetail: string;
  hasConvictions: boolean;
  convictionDetail: string;
  onProbation: boolean;
  onParole: boolean;
  personalContactName: string;
  personalContactPhone: string;
  date: Date;
}

async function createApplication(data: Application) {
  const response = await fetchFromApi("/application", "POST", data);
  return response.data;
}

export function useCreateApplicationMutation() {
  return useMutation({
    mutationFn: createApplication,
  });
}
