import React from "react";

import { Box, CssBaseline, CssVarsProvider } from "@mui/joy";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router";

function AppLayout() {
  return (
    <CssVarsProvider>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh", bgcolor: "background.level1" }}>
        <Sidebar />
        <Header />
        <Box
          component="main"
          className="MainContent"
          sx={{
            // pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

export default AppLayout;
