import { Box, Card, Checkbox, Grid, Stack, Typography } from "@mui/joy";
import { Circle, ContactPage, Email, Phone, School } from "@mui/icons-material";
import { Application } from "../../types/Application";
import { blue, green, orange, red, yellow } from "@mui/material/colors";

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return "";
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const annual_salary_map = [
  "$0 - $25,000",
  "$25,000 - $50,000",
  "$50,000 - 75,000",
  "75,000 - $100,000",
  "$100,000+",
];

export const employment_status_map = ["Unemployed", "Employed", "Student", "Retired", "Other"];

export const highest_education_map = [
  "N/A",
  "High School Diploma/GED",
  "Some College",
  "Bachelor's Degree",
  "Some Graduate work",
  "Master's Degree",
];

export const preferred_contact_map = ["Email", "Phone"];

export const weekly_hours_map = ["> 40", "32 - 40", "< 32"];

export const how_soon_map = ["Now", "In 6 months", "In 1 year", "Not Sure"];

export const application_status_map = [
  "New",
  "In Progress",
  "On Hold",
  "Enrolled",
  "Completed",
  "Rejected",
];

// const statusColor = (status: string) => {
//   switch (status) {
//     case "enrolled":
//       return "success";
//     case "rejected":
//       return "error";
//     case "new":
//       return "success";
//     default:
//       return "info";
//   }
// };

export const getStatusIcon = (status: number) => {
  console.log("Status", status);
  switch (status) {
    case 0:
      //New
      return <Circle sx={{ fontSize: "medium", color: blue[200] }} />;
    case 1:
      //In Progress
      return <Circle sx={{ fontSize: "medium", color: yellow[200] }} />;
    case 2:
      //On Hold
      return <Circle sx={{ fontSize: "medium", color: orange[200] }} />;
    case 3:
      //Enrolled
      return <School sx={{ fontSize: "large", color: blue[500] }} />;
    case 4:
      //Completed
      return <School sx={{ fontSize: "large", color: green[500] }} />;
    case 5:
      //Rejected
      return <Circle sx={{ fontSize: "medium", color: red[500] }} />;
  }
};

function ApplicationDetail({ application }: { application: Application }) {
  const {
    date,
    firstName,
    lastName,
    status,
    email,
    phone,
    preferredContact,
    dob,
    address1,
    city,
    state,
    zip,
    whyInterested,
    howSoon,
    highestEducation,
    employmentStatus,
    annualSalary,
    weeklyHours,
    hasLegalAction,
    legalDetail,
    hasConvictions,
    convictionDetail,
    haveSs,
    haveDl,
    haveBc,
    personalContactName,
    personalContactPhone,
  } = application;
  const applicationDate = date
    ? new Date(date).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      })
    : "";

  const dateOfBirth = dob
    ? new Date(dob).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      })
    : "";

  // const highest_education_map = [
  //   "High School Diploma",
  //   "Some College",
  //   "Bachelor's Degree",
  //   "Some Graduate work",
  //   "Master's Degree",
  // ];
  // const how_soon_map = ["Now", "In 6 months", "In 1 year", "Not Sure"];

  return (
    <Box>
      <Box>
        <Typography level="h4">Personal Information</Typography>
        <Typography level="body-md">
          <Box component={"span"} sx={{ mr: 1 }}>
            <b>Name:</b>
          </Box>
          {firstName} {lastName}
        </Typography>
        <Box component={"span"} sx={{ alignItems: "center", display: "inline-flex", mb: 0.5 }}>
          <Typography level="body-md" mr={1}>
            <b>Status:</b>
          </Typography>
          <Box component="b" sx={{ mr: 0.5 }}>
            {application_status_map[status!] || "New"}
          </Box>
          {getStatusIcon(status!)}
        </Box>
      </Box>
      <Typography level="body-md" mb={4}>
        <Box sx={{ alignItems: "center", display: "flex", mb: 0.5 }}>
          <Email sx={{ width: 20, mr: 0.5 }} /> <b>Email:</b>
          <Box ml={1} component="span">
            <Typography level="body-md">{email}</Typography>
          </Box>
        </Box>
        <Box sx={{ alignItems: "center", display: "flex", mb: 0.5 }}>
          <Phone sx={{ width: 20, mr: 0.5 }} /> <b>Phone:</b>
          <Box ml={1} component="span">
            <Typography level="body-md">{formatPhoneNumber(phone || "")}</Typography>
          </Box>
        </Box>
        <Box sx={{ alignItems: "center", display: "flex", mb: 0.5 }}>
          <ContactPage sx={{ width: 20, mr: 0.5 }} /> <b>Preferred Contact:</b>
          <Box ml={1} component="span">
            <Typography level="body-md">{preferredContact}</Typography>
          </Box>
        </Box>
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid xs={12} md={7}>
          <Card sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
            <Box mb={1}>
              <Typography level="h3" mb={1}>
                Application
              </Typography>
              <Typography level="body-md">
                <b>Application Date: </b>
                <Typography level="body-md" component="span">
                  {applicationDate}
                </Typography>
              </Typography>
              <Typography level="body-md">
                <b>Date of Birth: </b>
                {dateOfBirth}
              </Typography>
              <Typography level="h4" mt={2}>
                Contact
              </Typography>
              <Typography component="b">Address:</Typography>
              <Typography level="body-md">
                {address1}, {city}, {state} {zip}
              </Typography>
            </Box>
          </Card>
          <Card sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
            <Typography level="h3">Background</Typography>
            <Box mb={1}>
              <Typography component="b">
                Why are you interested in becoming a Merchant Seaman?
              </Typography>
              <Typography component="span">{whyInterested}</Typography>
              <Box mt={1}>
                <Typography component="b">How soon are you looking to start?</Typography>
                <Typography component="span">{how_soon_map[howSoon!]}</Typography>
              </Box>
            </Box>
            <Box mb={1}>
              <Typography component="b">Highest Education:</Typography>
              <Typography component="span">{highest_education_map[highestEducation!]}</Typography>
            </Box>
            <Box mb={1}>
              <Typography component="b">Current Employment:</Typography>
              <Typography component="span">
                {employment_status_map[employmentStatus!] || "N/A"}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography component="b">Annual Salary:</Typography>
              <Typography component="span">
                {annualSalary ? annual_salary_map[annualSalary] : ""}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography component="b">Weekly Hours:</Typography>
              <Typography component="span">{weekly_hours_map[weeklyHours!]}</Typography>
            </Box>
          </Card>
          <Card sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
            <Typography level="h3">Legal Information</Typography>
            <Box>
              <Typography level="body-md">
                <b>Open legal cases? </b>
                <Typography level="body-md" component="span">
                  {hasLegalAction ? "Yes" : "No"}
                </Typography>
              </Typography>
              {hasLegalAction && (
                <Box mb={1}>
                  <Typography component="b">Case Details:</Typography>
                  <Typography component="span">{legalDetail}</Typography>
                </Box>
              )}
            </Box>
            <Box mb={1}>
              <Typography level="body-md">
                <b>Convictions? </b>
                <Typography component="span">{hasConvictions ? "Yes" : "No"}</Typography>
              </Typography>

              {hasConvictions && (
                <Box mb={1}>
                  <Typography component="b">Conviction Details:</Typography>
                  {convictionDetail && <Typography component="span">{convictionDetail}</Typography>}
                </Box>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} md={5}>
          <Card sx={{ p: 2, mb: 2 }}>
            <Typography level="h3">Documents</Typography>
            <Stack spacing={2}>
              <Checkbox label="Social Security" checked={haveSs} />
              <Checkbox label="Driver's License" checked={haveDl} />
              <Checkbox label="Birth Certificate" checked={haveBc} />
            </Stack>
          </Card>
          <Card sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography level="h3">Emergency Contact</Typography>
            <Box mb={1}>
              <Typography component="b">Name</Typography>
              <Typography component="span">{personalContactName}</Typography>
            </Box>
            <Box mb={1}>
              <Typography component="b">Phone Number:</Typography>
              <Typography component="span">{personalContactPhone}</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ApplicationDetail;
