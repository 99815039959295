import { useQuery } from "@tanstack/react-query";
import { Application } from "../../types/Application";
import { fetchApplication } from "../../components/Application/List";

export const useApplicationQuery = (
  id: string
): {
  data: Application | undefined;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
} => {
  return useQuery<Application, unknown>({
    queryKey: ["application", id],
    queryFn: () => fetchApplication(id),
  });
};
