import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControl,
  Stack,
  Divider,
  Box,
  Select,
  Option,
} from "@mui/joy";
import { useCreateApplicationMutation } from "../../mutations/Application/useCreateMutation";
import InputField from "../Base/InputField";
import { useAuth } from "../Routes/PrivateRoute";
import { Application, emptyApplication } from "../../types/Application";
import {
  annual_salary_map,
  application_status_map,
  employment_status_map,
  highest_education_map,
  how_soon_map,
  preferred_contact_map,
} from "./Detail";
import { useSaveApplicationMutation } from "../../mutations/Application/useSaveApplicationMutation";
import { useRollbar } from "@rollbar/react";

const ApplicationForm: React.FC<{
  application?: Application;
  handleCancel?: () => void;
  setAppData?: (application: Application) => void;
}> = ({ application, handleCancel, setAppData }) => {
  const rollbar = useRollbar();
  const { id, ...appData } = application || {};
  const { isAuthenticated } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const createApplicationMutation = useCreateApplicationMutation();
  const saveApplicationMutation = useSaveApplicationMutation();
  const [formData, setFormData] = useState<Application>(
    application ? appData : { ...emptyApplication }
  );

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (application) {
      saveApplicationMutation.mutate(
        { id, ...formData },
        {
          onSuccess: () => {
            setAppData && setAppData(formData);
            isAuthenticated && handleCancel ? handleCancel() : setFormSubmitted(true);
          },
          onError: (error) => {
            rollbar.error("Error saving application", error);
            setError(
              "Error saving application. Please contact admin@theanchorprogram.org for more information."
            );
            console.log(error);
          },
        }
      );
      return;
    } else {
      createApplicationMutation.mutate(formData, {
        onSuccess: () => {
          setFormSubmitted(true);
        },
        onError: (error) => {
          rollbar.error("Error saving application", error);
          setError(
            "Error saving application. Please contact admin@theanchorprogram.org for more information."
          );
          console.log(error);
        },
      });
    }
  };

  const handleSelectChange = (name: string, value: number | string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onCancel = () => {
    return !!handleCancel ? handleCancel() : {};
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === "dob") {
      const dob = new Date(e.target.value).toISOString();
      setFormData({
        ...formData,
        [e.target.name]: new Date(dob.toString()),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCheckBoxSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.value === "") {
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: new Date(e.target.value),
    });
  };

  return (
    <>
      {error && (
        <Card sx={{ mb: 2, mt: { sm: 0, lg: 10 } }}>
          <CardContent>
            <Typography level="h4" mb={1}>
              Error
            </Typography>
            <Typography level="body-md">{error}</Typography>
          </CardContent>
        </Card>
      )}
      {!error && (
        <>
          {formSubmitted ? (
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography level="h4" mb={1}>
                  Application Submitted
                </Typography>
                <Typography level="body-md">
                  Your application has been submitted. We will reach out to you soon.
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <form onSubmit={handleSubmit}>
              <Card
                orientation="horizontal"
                sx={{ mb: 2, position: "sticky", top: 20, zIndex: 100 }}
              >
                <CardContent orientation="horizontal">
                  <Button type="submit">Submit</Button>
                  <Button variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                </CardContent>
              </Card>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography level="h4" mb={1}>
                    Personal Information
                  </Typography>
                  <Stack direction="column" spacing={1}>
                    <InputField
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    <FormControl>
                      <InputField
                        type="date"
                        name="dob"
                        placeholder="Date of Birth"
                        value={formData.dob?.toString()}
                        onChange={handleDateChange}
                      />
                    </FormControl>
                    {isAuthenticated && (
                      <Select
                        name="status"
                        placeholder="Application Status"
                        value={formData.status}
                        onChange={(_event, value) => {
                          handleSelectChange("status", value as number);
                        }}
                      >
                        {application_status_map.map((key, i) => (
                          <Option key={key} value={i}>
                            {key}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Stack>
                </CardContent>
              </Card>

              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography level="h4" mb={1}>
                    Contact
                  </Typography>
                  <Stack direction="column" spacing={1}>
                    <InputField
                      type="text"
                      name="address1"
                      value={formData.address1}
                      placeholder="Address"
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      name="city"
                      placeholder="City"
                      onChange={handleChange}
                      value={formData.city}
                    />
                    <InputField
                      type="text"
                      name="state"
                      placeholder="State"
                      onChange={handleChange}
                      value={formData.state}
                    />
                    <InputField
                      type="text"
                      name="zip"
                      placeholder="Zip"
                      onChange={handleChange}
                      value={formData.zip}
                    />
                  </Stack>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Stack direction="column" spacing={1}>
                    <InputField
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      onChange={handleChange}
                      value={formData.phone}
                    />
                    <InputField
                      type="text"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      value={formData.email}
                    />
                  </Stack>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Stack direction="column" spacing={1}>
                    <Select
                      name="preferredContact"
                      placeholder="How should we contact you?"
                      onChange={(_event, value) => {
                        handleSelectChange("preferredContact", value as number);
                      }}
                    >
                      {preferred_contact_map.map((key) => (
                        <Option key={key} value={key}>
                          {key}
                        </Option>
                      ))}
                    </Select>
                  </Stack>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Stack direction="column" spacing={1}>
                    <Typography component="b" mb={1}>
                      Personal Emergency Contact
                    </Typography>
                    <InputField
                      type="text"
                      name="personalContactName"
                      placeholder="Name"
                      value={formData.personalContactName}
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      name="personalContactPhone"
                      placeholder="Phone"
                      value={formData.personalContactPhone}
                      onChange={handleChange}
                    />
                  </Stack>
                </CardContent>
              </Card>

              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography level="h4" mb={1}>
                    Additional Information
                  </Typography>
                  <Stack direction="column" spacing={1}>
                    <InputField
                      type="text"
                      name="whyInterested"
                      placeholder="Why are you interested in becoming a Merchant Seaman?"
                      value={formData.whyInterested}
                      onChange={handleChange}
                    />
                    <InputField
                      type="text"
                      name="howSoon"
                      placeholder="How Soon"
                      value={how_soon_map[formData.howSoon!]}
                      onChange={handleChange}
                    />
                    <Select
                      name="highestEducation"
                      placeholder="What is your highest level of education?"
                      onChange={(_event, value) => {
                        handleSelectChange("highestEducation", value as number);
                      }}
                    >
                      {highest_education_map.map((key, i) => (
                        <Option key={key} value={i}>
                          {key}
                        </Option>
                      ))}
                    </Select>
                    {/* <Select
                name="highestEducation"
                placeholder="What is your highest level of education?"
                onSelectChange={handleSelectChange}
                value={formData.highestEducation}
              /> */}
                    <Box py={1}>
                      <Select
                        name="employmentStatus"
                        placeholder="Are you currently employed?"
                        onChange={(_event, value) => {
                          handleSelectChange("employmentStatus", value as number);
                        }}
                      >
                        {employment_status_map.map((key, i) => (
                          <Option key={key} value={i}>
                            {key}
                          </Option>
                        ))}
                      </Select>
                      {Boolean(formData.employmentStatus) && formData.employmentStatus !== 0 && (
                        <Select
                          name="annualSalary"
                          placeholder="Please estimate your current annual salary"
                          onChange={(_event, value) => {
                            handleSelectChange("annualSalary", value as number);
                          }}
                        >
                          {annual_salary_map.map((option, i) => (
                            <Option key={i} value={i}>
                              {option}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Box>
                  </Stack>
                </CardContent>
              </Card>

              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography level="h4" mb={1}>
                    Legal Information
                  </Typography>
                  <Stack direction="column" spacing={2}>
                    <Checkbox
                      label="Do you have a government issued copy of your birth certificate?"
                      name="haveBc"
                      onChange={handleCheckBoxSelect}
                      checked={formData.haveBc}
                    />
                    <Checkbox
                      label="Do you have a valid Driver's License or State ID?"
                      name="haveDl"
                      onChange={handleCheckBoxSelect}
                      checked={formData.haveDl}
                    />
                    <Checkbox
                      label="Do you have a Social Security Card?"
                      name="haveSs"
                      onChange={handleCheckBoxSelect}
                    />
                    <Checkbox
                      label="Are you willing take a drug test within 60 days if you are selected for this program? (Marijuana is still a prohibited substance for federal employees)."
                      name="isDrugFree"
                      onChange={handleCheckBoxSelect}
                    />
                    <Checkbox
                      label="Do you currently have any open legal cases?"
                      name="hasLegalAction"
                      onChange={handleCheckBoxSelect}
                    />

                    {formData.hasLegalAction && (
                      <InputField
                        type="text"
                        name="legalDetail"
                        placeholder="Please describe your case if possible."
                        value={formData.legalDetail}
                        onChange={handleChange}
                      />
                    )}

                    <Checkbox
                      label="Have you ever been convicted of a felony?"
                      name="hasConvictions"
                      checked={formData.hasConvictions}
                      onChange={handleCheckBoxSelect}
                    />

                    {formData.hasConvictions && (
                      <>
                        <InputField
                          type="text"
                          name="convictionDetail"
                          placeholder="Please describe your case if possible."
                          value={formData.convictionDetail}
                          onChange={handleChange}
                        />
                        <Checkbox
                          label="Are you currently on probation?"
                          name="onProbation"
                          onChange={handleCheckBoxSelect}
                        />
                        <Checkbox
                          label="Are you currently on parole?"
                          name="onParole"
                          onChange={handleCheckBoxSelect}
                        />
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </form>
          )}
        </>
      )}
      {isAuthenticated && (
        <Grid
          container
          justifyContent="center"
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            p: 2,
            zIndex: 1,
          }}
        >
          <Card
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Button variant="soft" onClick={() => navigate("/applications")}>
                Back to Applications
              </Button>
            </Box>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default ApplicationForm;
