import { useMutation } from "@tanstack/react-query";
import { fetchGoogleAuth } from "../../api/base";

const useGoogleAuthMutation = () => {
  type GoogleToken = {
    token: string;
  };
  return useMutation<{ response: any }, unknown, GoogleToken>({
    mutationFn: async (token) => {
      const response = await fetchGoogleAuth(token.token);
      return response;
    },
  });
};

export default useGoogleAuthMutation;
