import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "./ui/components/AppLayout";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ApplicationsRouter from "./components/Application/Router";
import Login from "./components/User/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import credentials from "./credentials.json";
import { AuthenticatedRedirect, PrivateRoute } from "./components/Routes/PrivateRoute";
import UnauthorizedPage from "./components/Page/Unauthorized";
import ConfirmUser from "./components/Page/ConfirmUser";
import Apply from "./components/Application/Apply";
import { Provider } from "@rollbar/react";

const queryClient = new QueryClient();
const googleClientId = credentials.web.client_id;

const rollbarConfig = {
  accessToken: process.env.REACT_APP_POST_CLIENT_ITEM_ACCESS_TOKEN,
  environment: process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <AppLayout />
      </PrivateRoute>
    ),
    errorElement: <div>404</div>,
    children: [
      {
        path: "/",
        element: <Navigate to="/applications" />,
      },

      {
        path: "applications/*",
        element: <ApplicationsRouter />,
      },
      {
        path: "users",
        element: <div>Users</div>,
        children: [
          {
            path: ":userId",
            element: <div>User</div>,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        element: (
          <AuthenticatedRedirect>
            <Login />
          </AuthenticatedRedirect>
        ),
      },
      {
        path: "register",
        element: <div>Register</div>,
      },
    ],
  },
  {
    path: "apply",
    element: <Apply />,
    children: [
      {
        path: "success",
        element: <div>Success</div>,
      },
    ],
  },
  {
    path: "privacy",
    element: <div>Privacy Policy</div>,
  },
  {
    path: "terms",
    element: <div>Terms of Service</div>,
  },
  {
    path: "/unauthorized",
    element: <UnauthorizedPage />,
  },
  {
    path: "/confirm-user/:id",
    element: <ConfirmUser />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
