import React from "react";
import { LogoutRounded as LogoutRoundedIcon } from "@mui/icons-material";
import { IconButton, Typography, Box } from "@mui/joy";

interface UserProfileProps {
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ firstName, lastName, email, picture }) => {
  const handleLogout = () => {
    localStorage.removeItem("google_credential");
    window.location.reload();
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Box component={"img"} src={picture} sx={{ width: 30, height: 30, borderRadius: 15 }} />
      <Box
        sx={{
          minWidth: 0,
          flex: 1,
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography component="div" level="body-sm">
          {firstName} {lastName}
        </Typography>
        <IconButton onClick={handleLogout}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default UserProfile;
