import { useState } from "react";
import { useParams } from "react-router";
import { useApplicationQuery } from "../../hooks/Application/useApplicationQuery";

import Detail from "./Detail";
import { Box, Button, Grid, Sheet } from "@mui/joy";
import ApplicationForm from "./Form";

const ApplicationProfile = () => {
  const [editMode, setEditMode] = useState(false);
  const { applicationId } = useParams();
  const { data: application } = useApplicationQuery(applicationId!);

  const handleEdit = () => {
    setEditMode(true);
  };

  console.log("edit mode:", editMode);

  return (
    <Sheet>
      <Grid
        container
        sx={(theme) => ({
          justifyContent: "flex-end",
          textAlign: "right",
          mr: 2,
          position: "sticky",
          top: 10,
          zIndex: 100,
          background: theme.palette.primary,
        })}
      >
        <Grid xs={12} md={4}>
          <Box sx={{ flexGrow: 1 }} mt={2}>
            {!editMode && (
              <>
                <Button color="primary" onClick={() => handleEdit()} sx={{ mr: 2 }}>
                  Edit
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingX: 2 }}>
        <Grid xs={12} lg={8} sx={{ mb: 6, mt: 2 }}>
          {application && (
            <>
              {editMode ? (
                <ApplicationForm
                  application={application}
                  handleCancel={() => setEditMode(false)}
                />
              ) : (
                <Detail application={application!} />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Sheet>
  );
};

export default ApplicationProfile;
