import { Box, Grid, Sheet, Typography } from "@mui/joy";
import { ReactComponent as Logo } from "../../assets/images/brand/tap-icon-logo.svg";
import ApplicationForm from "./Form";

const Apply = () => {
  return (
    <Sheet sx={{ paddingY: 4 }}>
      <Grid container spacing={2} sx={{ paddingX: 2 }} justifyContent={"center"}>
        <Grid xs={12} lg={3} sx={{ mb: 6, mt: 2 }}>
          <Box sx={{ position: "sticky", top: 20 }}>
            <Grid xs={12} md={1} mb={1} textAlign={"center"}>
              <Logo width={60} />
            </Grid>
            <Typography level="h3">Basic Mariner Training Program</Typography>
            <Typography level="body-lg" mb={2}>
              Application
            </Typography>
            <Typography level="body-md">
              The Basic Mariner Training Program will help you to obtain the required documentation
              to be eligible for entry-level careers as a Merchant Seaman. Please fill out this form
              and a TAP representative will contact you soon.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} lg={5} sx={{ mb: 6, mt: 2 }}>
          <ApplicationForm />
        </Grid>
      </Grid>
    </Sheet>
  );
};

export default Apply;
