import { useQuery } from "@tanstack/react-query";
import { fetchFromApi } from "../../api/base";

async function getRoles() {
  const response = await fetchFromApi("/auth/roles", "GET"); // replace with your API endpoint
  if (!response) {
    throw new Error("There was an error getting the roles");
  }
  return response;
}

export function useGetRolesQuery() {
  return useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });
}
