import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import useGoogleAuthMutation from "../../mutations/Auth/useGoogleAuthMutation";
import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/joy";
import { ReactComponent as Logo } from "../../assets/images/brand/tap-icon-logo.svg";
import StaticLayout from "../Page/StaticLayout";

const Login = (): JSX.Element => {
  const [googleAuth, setGoogleAuth] = useState<any>(null);
  const navigate = useNavigate();
  const authMutation = useGoogleAuthMutation();

  const handleLoginSuccess = async (data: any) => {
    setGoogleAuth(data);
    const token = data.credential;
    authMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          localStorage.setItem("google_credential", JSON.stringify(data));
          navigate("/");
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  useEffect(() => {
    if (googleAuth && googleAuth.credentials) {
      authMutation.mutate(googleAuth.credentials);
    }
  }, [googleAuth, authMutation]);

  return (
    <StaticLayout>
      <Box mb={1}>
        <Logo width="75" />
      </Box>
      <Typography level="h3" component="h1" mb={2} gutterBottom>
        Login
      </Typography>
      <GoogleLogin onSuccess={handleLoginSuccess} onError={() => console.log("Login failed")} />
    </StaticLayout>
  );
};

export default Login;
