export type Application = {
  address1?: string;
  annualSalary?: number;
  city?: string;
  convictionDetail?: string;
  date?: Date;
  dob?: Date;
  email?: string;
  employmentStatus?: number;
  firstName?: string;
  hasConvictions?: boolean;
  hasLegalAction?: boolean;
  haveBc?: boolean;
  haveDl?: boolean;
  haveSs?: boolean;
  highestEducation?: number;
  howSoon?: number;
  id?: string;
  isDrugFree?: boolean;
  lastName?: string;
  legalDetail?: string;
  onParole?: boolean;
  onProbation?: boolean;
  personalContactName?: string;
  personalContactPhone?: string;
  phone?: string;
  preferredContact?: string;
  programId?: string;
  state?: string;
  status?: number;
  userId?: string;
  weeklyHours?: number;
  whyInterested?: string;
  zip?: string;
};

export const emptyApplication: Application = {
  address1: "",
  annualSalary: 0,
  city: "",
  convictionDetail: "",
  date: new Date(),
  dob: new Date(),
  email: "",
  employmentStatus: 0,
  firstName: "",
  hasConvictions: false,
  hasLegalAction: false,
  haveBc: false,
  haveDl: false,
  haveSs: false,
  highestEducation: 0,
  howSoon: 0,
  isDrugFree: false,
  lastName: "",
  legalDetail: "",
  onParole: false,
  onProbation: false,
  personalContactName: "",
  personalContactPhone: "",
  phone: "",
  preferredContact: "",
  state: "",
  status: 0,
  userId: "",
  weeklyHours: 0,
  whyInterested: "",
  zip: "",
};
