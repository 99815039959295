import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  FormControl,
  Select,
  FormLabel,
  Input,
  Option,
  Card,
  CardContent,
  Box,
  Stack,
  Textarea,
} from "@mui/joy";
import StaticLayout from "./StaticLayout";
import { useAuth } from "../Routes/PrivateRoute";
import { fetchFromApi } from "../../api/base";
import { ReactComponent as Logo } from "../../assets/images/brand/tap-icon-logo.svg";
import { useNavigate } from "react-router";

const UnauthorizedPage = () => {
  const [email, setEmail] = useState("");
  const [inquiryType, setInquiryType] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const { isAuthenticated, profile } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      setEmail(profile.email);
    }
  }, [isAuthenticated, profile]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // const handleInquiryTypeChange = (
  //   event: React.SyntheticEvent<{ value: unknown }>,
  //   newValue: string | null
  // ) => {
  //   const _event = event as React.ChangeEvent<{ value: unknown }>;
  //   console.log(_event.target.value);
  //   setInquiryType(newValue as string);
  // };

  const handleInquiryTypeChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
    setInquiryType(newValue as string);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const res = await fetchFromApi("/users/request-access", "POST", {
      email,
      type: inquiryType,
      message,
    });
    setSuccessMessage(res);
  };

  const handleLogout = () => {
    localStorage.removeItem("google_credential");
    navigate("/");
  };

  return (
    <StaticLayout>
      <Card sx={{ mb: 2, width: 450 }} size="md">
        <CardContent sx={{ alignItems: "center", display: "flex", p: 2 }}>
          <Box sx={{ mb: 1 }}>
            <Logo width={50} />
          </Box>
          <Typography level="h3" component="h1" mb={2} gutterBottom>
            Community Resources
          </Typography>
          {successMessage ? (
            <Box mb={2}>
              <Typography level="body-sm" variant="outlined" color="success" p={2}>
                {successMessage}
              </Typography>
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Typography level="body-sm">
                You are not currently registered for this site. To request access, please select an
                option below or email us directly at{" "}
                <a href="mailto:admin@theanchorprogram.org">admin@theanchorprogram.org</a>.
              </Typography>
              <br />
              <Typography level="body-sm">
                Please fill out the form below to request access to the site.
              </Typography>
              <Stack width={350} spacing={2} sx={{ mt: 2, mb: 4 }}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                />

                <FormControl required>
                  <FormLabel component="legend">Inquiry Type</FormLabel>
                  <Select id="inquiry-type" value={inquiryType} onChange={handleInquiryTypeChange}>
                    <Option value="Administrator">Administrator</Option>
                    <Option value="Potential Student">Potential Student</Option>
                    <Option value="Community Organization">Community Organization</Option>
                    <Option value="Potential Funder">Potential Funder</Option>
                  </Select>
                </FormControl>
                <FormLabel htmlFor="message">Message</FormLabel>
                <Textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  minRows={3}
                />
                <Button type="submit" color="primary">
                  Request Access
                </Button>
                <Button onClick={handleLogout} variant="outlined">
                  Logout
                </Button>
              </Stack>
            </form>
          )}
          <Typography level="body-xs">
            For more information, please contact us at admin@theanchorprogram.org.
          </Typography>
        </CardContent>
      </Card>
    </StaticLayout>
  );
};

export default UnauthorizedPage;
